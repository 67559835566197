import React from 'react';

class Menu extends React.Component {

    render(){
        const myVal = '123';
        return(
            <div className="Menu">
                <h1>Shopping list {myVal} </h1>
                <ul>
                    <li>Option One</li>
                    <li>Option Two</li>
                </ul>
            </div>
        );
    }
}

export default Menu;
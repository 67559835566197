import React, { useState } from 'react';

function SampleHook(){

const [count, setCount] = useState(0);
    return (
        <div>
            <p> You have clicked {count} Times </p>
            <button onClick={()=>{setCount(count +1)}}>Increase Count</button>
        </div>
    )
}

export default SampleHook

import React from 'react';
import logo from './logo.svg';
import './App.css';
import SampleHook from './SampleHooks';
import Menu from './components/Menu';

function App() {
  return (
    <div>
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Reach us for more queries.
        </p>
        <a
          className="App-link"
          href="https://bindinfo.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us
        </a>
      <SampleHook />
      <Menu/>
      </header>
    </div>
    </div>
  );
}

export default App;
